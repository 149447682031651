import { createGlobalStyle } from 'styled-components'

import AeroportWoff from '../fonts/subset-Aeroport.woff'
import AeroportWoff2 from '../fonts/subset-Aeroport.woff2'
import AeroportBoldWoff from '../fonts/subset-Aeroport-Bold.woff'
import AeroportBoldWoff2 from '../fonts/subset-Aeroport-Bold.woff2'

import RFDewiExpandedBoldWoff from '../fonts/subset-RFDewiExpanded-Bold.woff'
import RFDewiExpandedBoldWoff2 from '../fonts/subset-RFDewiExpanded-Bold.woff2'

export const theme = {
  fonts: {
    main: 'Aeroport, -apple-system, sans-serif, Arial',
    headers: 'RF Dewi Expanded, -apple-system, sans-serif, Arial'
  },
  colors: {
    deepblue: 'hsl(227, 48%, 17%)',
    blue: 'hsl(219, 100%, 49%)',
    milk: 'hsl(36, 22%, 95%)',
    milkTransparent: 'hsla(36, 22%, 95%, 20%)',
    red: 'hsl(360, 94%, 57%)'
  },
  breakpoints: {
    mobile: 'only screen and (max-width: 576px)',
    tablet: 'only screen and (max-width: 768px)',
    tabletPlus: 'only screen and (max-width: 1024px)',
    notebook: 'only screen and (max-width: 1200px)'
  },
  animations: {
    linksColor: 'color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)'
  },
  shadows: {}
}

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'RF Dewi Expanded';
        src: local('RF Dewi Expanded Bold'), local('RFDewiExpanded-Bold'),
            url(${RFDewiExpandedBoldWoff2}) format('woff2'),
            url(${RFDewiExpandedBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Aeroport';
        src: url(${AeroportWoff2}) format('woff2'),
            url(${AeroportWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Aeroport';
        src: url(${AeroportBoldWoff2}) format('woff2'),
            url(${AeroportBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
    body,html {
        background-color: ${({ theme }) => theme.colors.milk};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        color: ${({ theme }) => theme.colors.deepblue};
        font-family: ${({ theme }) => theme.fonts.main}!important;
        font-size: 16px;
        line-height: 16px;
    }
    body {
        background-color: ${({ theme }) => theme.colors.milk};
        pointer-events: inherit!important;
    }

    a {
        color: ${({ theme }) => theme.colors.blue};
        text-decoration: none;
        transition: ${({ theme }) => theme.animations.linksColor};
    }
    a:hover {
        color: ${({ theme }) => theme.colors.red};
        text-decoration: underline;
    }

    h1 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 3.25rem;
        line-height: 3.5rem;
        margin: 0;
        @media ${({ theme }) => theme.breakpoints.tabletPlus} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        @media ${({ theme }) => theme.breakpoints.tablet} {
            font-size: 2rem;
            line-height: 2rem;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    h2 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 2rem;
        line-height: 2rem;
        margin: 0;
        @media ${({ theme }) => theme.breakpoints.tabletPlus} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        @media ${({ theme }) => theme.breakpoints.tablet} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    h3 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 0;
        @media ${({ theme }) => theme.breakpoints.tabletPlus} {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
        @media ${({ theme }) => theme.breakpoints.tablet} {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
    }

    .shadow {
        box-shadow: 0px 10px 20px rgba(69, 82, 101, 0.05);
    }
    .radius-16 {
        border-radius: 1rem;
    }
    .radius-32 {
        border-radius: 2rem;
    }
    
    .margin-bottom-20 {
        margin-bottom: 1.25rem;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            margin-bottom: 0.75rem;
        }
    }

    .margin-top-20 {
        margin-top: 1.25rem!important;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            margin-top: 0.75rem!important;
        }
    }

    .margin-top-60 {
        margin-top: 3.75rem!important;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            margin-top: 2.5rem!important;
        }
    }

    //Swal
    .swal2-popup {
        border-radius: 2rem!important;
    }
    .swal2-title {
        font-size: 1.5rem!important;
        font-family: ${({ theme }) => theme.fonts.headers}!important;
        color: ${({ theme }) => theme.colors.blue}!important;
        font-weight: normal!important;
    }
    .swal2-html-container {
        font-size: 1rem!important;
        color: ${({ theme }) => theme.colors.deepblue}!important;
    }
    .swal2-styled.swal2-confirm {
        border-radius: 100px!important;
        padding: 1.15rem 1.5rem!important;
        font-size: 1.15rem!important;
        line-height: 1.5rem!important;
    }
    .swal2-no-war {
        display: none!important;
    }

    //Forms
    .Form > div {
        position: relative;
    }

    .label {
        margin-bottom: 0.5rem;
    }

    .input {
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colors.deepblue};
        border: 1px solid ${({ theme }) => theme.colors.milk};
        border-radius: 1rem;
        padding: 1rem;
        display: block;
        width: 100%;
        margin-bottom: 1.75rem;
        outline: 0;
    }
    .input:focus {
        box-shadow: 0 0 0 0.25rem rgba(134, 39, 162, 0.25);
    }

    .input.isInvalid {
        border-color: ${({ theme }) => theme.colors.red};
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M9.75 9.75L14.25 14.25" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M14.25 9.75L9.75 14.25" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
        background-position: right 1.25rem center;
        background-repeat: no-repeat;
    }
    .input.isInvalid:focus {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }

    .Form .loading {
        position: absolute !important;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Form .loading img{
        width: 4rem;
        height: 4rem;
    }
    .error {
        position: absolute;
        bottom: 0.5rem;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.active};
    }
`
